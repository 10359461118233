import { useEffect, useState, useCallback, useRef } from "react";

import { useMedaliaSDK } from "app/hooks/use-medallia-sdk.hook";
import { useEventListener } from "app/hooks/use-event-listener.hook";
import { useModalState } from "shared/components/modal/hooks/use-modal-state.hook";
import { MedalliaEventListeners } from 'app/globals/constants';

/**
 * A React hook that allows to interact with a Medallia form in a Modal, this will track submits and cancel over the same form.
 *
 * @param name Modal name
 * @param formId Medallia form id.
 * @param onComplete Function that will be executed when the user clicks. You must include the closing modal logic here.
 * @returns A function to close the form in the Medallia lifecycle form.
 */
export const useMedaliaModalForm = (name: string, formId: number, onComplete: Function) => {
  const { visible } = useModalState(name);
  const [submitted, setSubmitted] = useState(false);
  const submitRef = useRef(false);
  const { resetForm, closeForm } = useMedaliaSDK(formId);

  const handleOnComplete = useCallback((event) => {
    setSubmitted(true);
    onComplete();
  }, []);

  const handleOnSubmit = useCallback((event) => {
    setSubmitted(true);
  }, []);

  const close = () => {
    if (submitRef.current) {
      closeForm();
    }
  };

  useEffect(() => {
    submitRef.current = submitted;
  }, [submitted]);

  useEffect(() => {
    if (visible) {
      resetForm();
      setSubmitted(false);
    }
  }, [visible]);

  useEventListener(MedalliaEventListeners.FORM_CLOSE_SUBMITTED, handleOnComplete);
  useEventListener(MedalliaEventListeners.FORM_CLOSE_NO_SUBMIT, handleOnComplete);
  useEventListener(MedalliaEventListeners.SUBMIT_FEEDBACK, handleOnSubmit);

  return [close];
};
