import { Action } from 'redux';
import { AjaxError } from 'rxjs/observable/dom/AjaxObservable';

import { ShippingContainer } from 'shared/models/shipments/shipping-container.model';
import { ContainerStatus } from 'shared/models/shipments/container-status.model';
import { EquipmentInfo } from 'shared/models/shipments/equipment-info.model';

export const FETCH_CONTAINERS = 'FETCH_CONTAINERS';
export const FETCH_CONTAINERS_SUCCESS = 'FETCH_CONTAINERS_SUCCESS';
export const SET_CONTAINER_STATUS = 'SET_CONTAINER_STATUS';
export const SET_CONTAINER_STATUS_SUCCESS = 'SET_CONTAINERS_STATUS_SUCCESS';
export const FETCH_CONTAINER_STATUSES_SUCCESS = 'FETCH_CONTAINER_STATUSES_SUCCESS';
export const FETCH_CONTAINER_UPDATE_FAILURE = 'FETCH_CONTAINER_UPDATE_FAILURE';
export const FETCH_EQUIPMENT_INFO_SUCCESS = 'FETCH_EQUIPMENT_INFO_SUCCESS';

export interface FetchContainersAction extends Action {
  loadNumber: number;
  bookType: string;
  bookSeqNum: number;
}

export interface FetchContainersSuccessAction extends Action {
  payload: ShippingContainer[];
}

export interface SetContainerStatusAction extends Action {
  loadNumber: number;
  containers: ShippingContainer[];
}

export interface SetContainerStatusSuccessAction extends Action {
  payload: ShippingContainer[];
}

export interface FetchContainerStatusesSuccessAction extends Action {
  payload: ContainerStatus[];
}

export interface FetchContainerUpdateFailureAction extends Action {
  error: AjaxError;
}

export interface FetchEquipmentInfoSuccessAction extends Action {
  payload: EquipmentInfo;
}

export const fetchContainers = (loadNumber: number, bookType: string, bookSeqNum: number): FetchContainersAction => ({type: FETCH_CONTAINERS, loadNumber, bookType, bookSeqNum});
export const fetchContainersSuccess = (payload: ShippingContainer[]): FetchContainersSuccessAction => ({type: FETCH_CONTAINERS_SUCCESS, payload});
export const setContainerStatus = (loadNumber: number, containers: ShippingContainer[]): SetContainerStatusAction => ({type: SET_CONTAINER_STATUS, loadNumber, containers});
export const setContainerStatusSuccess = (payload: ShippingContainer[]): SetContainerStatusSuccessAction => ({type: SET_CONTAINER_STATUS_SUCCESS, payload});
export const fetchContainerStatusesSuccess = (payload: ContainerStatus[]): FetchContainerStatusesSuccessAction => ({type: FETCH_CONTAINER_STATUSES_SUCCESS, payload});
export const fetchEquipmentInfoSuccess = (payload: EquipmentInfo): FetchEquipmentInfoSuccessAction => ({type: FETCH_EQUIPMENT_INFO_SUCCESS, payload});

export const fetchContainerUpdateFailure = (error: AjaxError): FetchContainerUpdateFailureAction => ({type: FETCH_CONTAINER_UPDATE_FAILURE, error});
