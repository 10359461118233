import React from 'react';
import classNames from 'classnames';
import { useCallback } from 'react';

import { Modal } from 'shared/components/modal/modal.component';
import { useMedaliaModalForm } from 'app/hooks/use-medallia-form.hook';
import { MedalliaFormsId } from 'app/globals/constants';

import './feedback.component.scss';

export interface Props {
  onComplete: () => any;
  onCancel: () => any;
  onHide?: () => any;
  name?: string;
  cancelButtonKey?: ResourceKey;
}

export const Feedback: React.FC<Props> = ({name = 'feedback', cancelButtonKey = 'CANCEL', ...props}) => {
  const [closeForm] = useMedaliaModalForm(name, MedalliaFormsId.FEEDBACK_FORM, props.onComplete);

  const onHide = useCallback(() => {
    closeForm();
    if (props.onHide) {
      props.onHide();
    }
  }, [props.onHide]);

  return (
    <Modal
      name={name}
      track
      isForm
      onHide={onHide}
      children={() =>
        <div className={classNames('chr-feedback-component', name)}>
          <div className="chr-feedback">
            <div id="carrier-web-feedback-form" />
          </div>
        </div>
      }
    />
  );
};
