import { Container } from "typedi";

import { MedalliaSDKRepository } from "app/repositories/medallia-onsite-sdk.repository";

/**
 * A React hook that allows interaction with Medallia forms.
 *
 * @param formId Medallia form id.
 * @returns Functions to handle Medallia form.
 */
export const useMedaliaSDK = (formId: number) => {
  const repo = Container.get(MedalliaSDKRepository);

  const closeForm = () => repo.closeForm(formId);
  const loadForm = () => repo.loadForm(formId);
  const showForm = () => repo.showForm(formId);
  const resetForm = () => repo.resetForm(formId);
  const updateView = () => repo.updateView(formId);

  return { loadForm, showForm, resetForm, updateView, closeForm };
};
