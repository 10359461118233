import { Service } from "typedi";
//TODO: Create a function or hook to handle existance of storage

@Service()
export class MedalliaSDKRepository {
  private get storage() {
    return window["KAMPYLE_ONSITE_SDK"];
  }

  private get kampyleUtilStorage() {
    return window["KAMPYLE_UTILS"];
  }

  private get kampyleFuncStorage() {
    return window["KAMPYLE_FUNC"];
  }

  public closeForm(formId: number) {
    if (this.storage && typeof this.storage.closeForm === "function") {
      this.storage.closeForm(formId);
    }
  }

  public isFormLoaded(formId: number): boolean {
    if (this.kampyleFuncStorage && typeof this.kampyleFuncStorage.isFormLoaded === "function") {
      return this.kampyleFuncStorage.isFormLoaded(formId);
    }
    return false;
  }

  public removeContent(formId: number) {
    if (this.kampyleUtilStorage && typeof this.kampyleUtilStorage.removeAllContent === "function") {
      const formElement = document.getElementById(`embeddedIframe${formId}`);
      this.kampyleUtilStorage.removeAllContent(formElement);
    }
  }

  public updateView(formId: number) {
    if (this.storage && typeof this.storage.updatePageView === "function") {
      this.removeContent(formId);
      this.storage.updatePageView();
    }
  }

  public loadForm(formId: number) {
    if (this.storage && typeof this.storage.loadForm === "function") {
      this.storage.loadForm(formId);
    }
  }

  public showForm(formId: number) {
    if (this.storage && typeof this.storage.showForm === "function") {
      this.storage.showForm(formId);
    }
  }

  public resetForm(formId: number) {
    this.updateView(formId);
    this.loadForm(formId);
    this.showForm(formId);
  }
}
